@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  --dark_bg: #121212;
  --light_bg: #fff;
  --light_txt: #fff;
  --dots:#D9D9D9;
  --team_bg:#DBDBDB;
  --base_primary: #ee4748;
  --base_primary2: #EC3237;
  --haiti: #211f26;
  --font_white_color:rgba(255, 255, 255, 0.88);
}

@font-face {
  font-family: "Be Vietnam Light";
  src: url("./font/BeVietnam-Light.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Be Vietnam Regular";
  src: url("./font/BeVietnam-Regular.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Be Vietnam Medium";
  src: url("./font/BeVietnam-Medium.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Be Vietnam SemiBold";
  src: url("./font/BeVietnam-SemiBold.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Be Vietnam Bold";
  src: url("./font/BeVietnam-Bold.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Be Vietnam ExtraBold";
  src: url("./font/BeVietnam-ExtraBold.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Harry p";
  src: url("./font/HARRYP__.TTF") format("woff2");
  font-weight: normal;
  font-style: normal;
}
.Fw300 {
  font-family: "Be Vietnam Light";
}

.Fw400 {
  font-family: "Be Vietnam Regular";
}

.Fw500 {
  font-family: "Be Vietnam Medium";
}

.Fw600 {
  font-family: "Be Vietnam SemiBold";
}

.Fw700 {
  font-family: "Be Vietnam Bold";
}

.Fw800 {
  font-family: "Be Vietnam ExtraBold";
}

body {
  font-family: "Be Vietnam Regular";
}

@media screen and (max-width: 500px) {
  .show_mobile {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  background: var(--dark_bg);
  color: var(--light_txt);
  margin: 0;
  overflow: hidden;
}
body .Landing {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
body #root {
  overflow: hidden;
}
body ::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}
body .container {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 min(7.3206442167vw, 100px);
}
body .book-a-call {
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: var(--base_primary);
  padding: min(3.023255814vw, 13px) min(4.1860465116vw, 18px);
  border-radius: 100px;
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .book-a-call {
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
    padding: min(3.2558139535vw, 14px) min(4.1860465116vw, 18px);
  }
}
body .cust-btn {
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  padding: 13px 18px 17px 18px;
  border-radius: 100px;
  background: var(--base_primary);
}
body .cust-btn > div {
  display: flex;
}
@media screen and (max-width: 900px) {
  body .cust-btn {
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
    padding: min(3.2558139535vw, 14px) min(5.5813953488vw, 24px);
  }
}
body .wand_img {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: -1;
}
body .wand_img .wand_holder {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
body .wand_img .wand_holder .wand_main {
  height: min(14.6412884334vw, 200px);
  width: min(14.6412884334vw, 200px);
}
body .wand_img .wand_holder .wand_highlight {
  height: min(9.4436310395vw, 129px);
  width: min(9.4436310395vw, 129px);
  position: absolute;
  top: min(-1.0980966325vw, -15px);
  right: min(-3.5871156662vw, -49px);
}
body .mobile_img_client {
  display: flex;
  position: relative;
  height: 100%;
}
body .mobile_img_client .mob_img1 {
  position: absolute;
  z-index: 1;
  width: 53.84%;
  right: 0;
  bottom: 0;
  border-radius: 20px;
}
@media screen and (max-width: 800px) {
  body .mobile_img_client .mob_img1 {
    width: 70%;
  }
}
@media screen and (max-width: 850px) {
  body .mobile_img_client .mob_img1 {
    width: 75%;
  }
}
@media screen and (max-width: 800px) {
  body .mobile_img_client .mob_img1 {
    width: 80%;
  }
}
@media screen and (max-width: 750px) {
  body .mobile_img_client .mob_img1 {
    width: 85%;
  }
}
@media screen and (max-width: 700px) {
  body .mobile_img_client .mob_img1 {
    width: 100%;
  }
}
body .mobile_img_client .mob_img2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 53.84%;
  border-radius: 20px;
}
@media screen and (max-width: 800px) {
  body .mobile_img_client .mob_img2 {
    width: 70%;
  }
}
@media screen and (max-width: 850px) {
  body .mobile_img_client .mob_img2 {
    width: 75%;
  }
}
@media screen and (max-width: 800px) {
  body .mobile_img_client .mob_img2 {
    width: 80%;
  }
}
@media screen and (max-width: 750px) {
  body .mobile_img_client .mob_img2 {
    width: 85%;
  }
}
@media screen and (max-width: 700px) {
  body .mobile_img_client .mob_img2 {
    width: 100%;
  }
}
body .main_star {
  position: relative;
}
body .main_star .star1 {
  height: min(5.1244509517vw, 70px);
  width: min(5.1244509517vw, 70px);
}
@media screen and (max-width: 800px) {
  body .main_star .star1 {
    height: min(9.5348837209vw, 41px);
    width: min(9.5348837209vw, 41px);
  }
}
body .main_star .star2 {
  position: absolute;
  right: calc(100% - min(0.7320644217vw, 10px));
  height: min(2.196193265vw, 30px);
  width: min(2.196193265vw, 30px);
}
@media screen and (max-width: 800px) {
  body .main_star .star2 {
    height: min(4.6511627907vw, 20px);
    width: min(4.6511627907vw, 20px);
  }
}
body .main_star .star3 {
  position: absolute;
  right: calc(100% - min(1.8301610542vw, 25px));
  bottom: 0;
  height: min(2.196193265vw, 30px);
  width: min(2.196193265vw, 30px);
}
@media screen and (max-width: 800px) {
  body .main_star .star3 {
    height: min(4.6511627907vw, 20px);
    width: min(4.6511627907vw, 20px);
  }
}
body .main_star svg .dotted_line {
  stroke-dasharray: min(87.8477306003vw, 1200px) min(14.6412884334vw, 200px);
  animation: dot_animate 3s linear infinite;
}
@media screen and (max-width: 800px) {
  body .main_star svg .dotted_line {
    stroke-dasharray: min(186.0465116279vw, 800px) min(69.7674418605vw, 300px);
  }
}
@keyframes dot_animate {
  0% {
    stroke-dashoffset: min(106.149341142vw, 1450px);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@media screen and (max-width: 800px) {
  @keyframes dot_animate {
    0% {
      stroke-dashoffset: min(209.3023255814vw, 900px);
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}
body .main_star svg .dotted_line2 {
  stroke-dasharray: min(87.8477306003vw, 1200px) min(14.6412884334vw, 200px);
  animation: dot_animate2 3s linear infinite;
}
@media screen and (max-width: 800px) {
  body .main_star svg .dotted_line2 {
    stroke-dasharray: min(186.0465116279vw, 800px) min(46.511627907vw, 200px);
  }
}
@keyframes dot_animate2 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: min(106.149341142vw, 1450px);
  }
}
@media screen and (max-width: 800px) {
  @keyframes dot_animate2 {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: min(186.0465116279vw, 800px);
    }
  }
}
body .navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 92px;
  width: 100%;
  box-shadow: 0px 2px 1px 0px rgba(126, 126, 126, 0.15);
  background: var(--dark_bg);
  padding: 20px 0;
  z-index: 100;
}
body .navbar > .container {
  display: flex;
  height: 100%;
  align-items: center;
}
@media screen and (max-width: 900px) {
  body .navbar > .container {
    justify-content: space-between !important;
    padding: 0 27px;
  }
}
body .navbar > .container .mobile_hamberger {
  display: none;
}
@media screen and (max-width: 900px) {
  body .navbar > .container .mobile_hamberger {
    display: block;
    margin: 0 0 0 30px;
    cursor: pointer;
  }
}
body .navbar > .container .logo {
  height: 100%;
  width: auto;
  margin: 0 auto 0 0;
}
@media screen and (max-width: 900px) {
  body .navbar > .container .logo {
    margin: 0;
  }
}
body .navbar > .container .book-a-call {
  margin: 0 0 0 60px;
}
@media screen and (max-width: 900px) {
  body .navbar > .container .book-a-call {
    margin: 0;
  }
}
@media screen and (max-width: 900px) {
  body .navbar > .container .book-a-call {
    display: none;
  }
}
body .navbar > .container .nav-link-group {
  display: flex;
  gap: 0 60px;
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Light";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .navbar > .container .nav-link-group {
    display: none;
  }
}
body .navbar > .container .nav-link-group .nav-link {
  opacity: 0.88;
  position: relative;
}
body .navbar > .container .nav-link-group .nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--base_primary);
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.3s linear;
  transform-origin: right top;
}
body .navbar > .container .nav-link-group .nav-link.active {
  opacity: 1;
}
body .navbar > .container .nav-link-group .nav-link.active::before {
  transform: scaleX(1);
  transform-origin: left top;
}
body .navbar > .container .blank_space {
  display: none;
}
@media screen and (max-width: 500px) {
  body .navbar > .container .blank_space {
    display: block;
    margin: 0 30px 0 0;
    visibility: hidden;
  }
}
body .navbar .nav-link-group_mobile {
  position: absolute;
  top: 92px;
  font-size: min(4.1860465116vw, 18px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  display: flex;
  flex-direction: column;
  height: 0px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  padding: 0;
  transition: height 0.3s linear, padding 0.3s linear;
  background-color: #121212;
  color: white;
}
body .navbar .nav-link-group_mobile.hamActive {
  padding: 20px 0;
  height: 280px;
}
body .navbar .nav-link-group_mobile .nav-link {
  position: relative;
  padding: 20px 0;
  cursor: pointer;
  font-weight: 900 !important;
}
body .navbar .nav-link-group_mobile .book-a-call {
  margin: auto;
}
body .cust-video {
  width: min(39.7510980966vw, 543px);
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 16px;
  overflow: hidden;
}
body .cust-video > video {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
body .cust-video-iframe {
  width: min(45.2415812592vw, 618px);
  height: min(22.6207906296vw, 309px);
  border-radius: 16px;
  overflow: hidden;
}
body .cust-video-iframe > * {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 700px) {
  body .cust-video-iframe {
    width: 100%;
  }
}
body .dots_collection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin: min(2.9282576867vw, 40px) 0 0 0;
}
body .dots_collection .arraow {
  background-color: var(--dots);
  height: 32px;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
body .dots_collection .arraow.opas {
  opacity: 0.7;
  cursor: no-drop;
}
body .dots_collection .singleline {
  height: 1px;
  width: 61px;
  background-color: #fff;
  opacity: 0.2;
}
@media screen and (max-width: 600px) {
  body .dots_collection {
    margin: min(5.8139534884vw, 25px) 0 0 0;
  }
}
body .dots_collection .dots {
  background-color: var(--dots);
  height: min(0.7320644217vw, 10px);
  width: min(0.7320644217vw, 10px);
  border-radius: 50px;
}
@media screen and (max-width: 700px) {
  body .dots_collection .dots {
    height: min(2.0930232558vw, 9px);
    width: min(2.0930232558vw, 9px);
  }
}
body .dots_collection .activedots {
  background-color: var(--base_primary);
}
body .arraow_collection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: min(2.9282576867vw, 40px) 0 0 0;
}
@media screen and (max-width: 600px) {
  body .arraow_collection {
    margin: min(5.8139534884vw, 25px) 0 0 0;
  }
}
body .arraow_collection .arraow {
  background-color: var(--dots);
  height: 32px;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
body .arraow_collection .arraow.opas {
  cursor: no-drop;
  background-color: #9d9d9d;
}
body .arraow_collection .arraow.opas svg {
  opacity: 0.7;
}
body .arraow_collection .singleline {
  height: 1px;
  width: 61px;
  background-color: #fff;
  opacity: 0.2;
}
@media screen and (max-width: 600px) {
  body .arraow_collection .singleline {
    width: 46px;
  }
}
body .footer {
  padding: min(4.6852122987vw, 64px) 0 min(1.8301610542vw, 25px) 0;
  border-top: 1px solid rgba(255, 255, 255, 0.267);
}
@media screen and (max-width: 700px) {
  body .footer {
    padding: min(6.976744186vw, 30px) 0 min(6.976744186vw, 30px) 0;
  }
}
body .footer .footer_main {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto min(4.3191800878vw, 59px) auto;
}
body .footer .footer_main .footer1 {
  width: 20%;
}
body .footer .footer_main .footer1 img {
  width: min(4.5387994143vw, 62px);
  height: auto;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer1 {
    display: none;
  }
}
body .footer .footer_main .footer2 {
  width: 52%;
  padding: 0 min(7.5402635432vw, 103px) 0 0;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer2 {
    width: 100%;
    padding: 0;
  }
}
body .footer .footer_main .footer2 .title {
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer2 .title {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .footer .footer_main .footer2 .svg_holder {
  display: flex;
  align-items: flex-start;
}
body .footer .footer_main .footer2 .svg_holder svg {
  width: min(2.0497803807vw, 28px);
  height: auto;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer2 .svg_holder svg {
    width: min(4.1860465116vw, 18px);
  }
}
body .footer .footer_main .footer2 a {
  height: -moz-fit-content;
  height: fit-content;
}
body .footer .footer_main .footer2 .contact {
  display: flex;
  gap: min(1.756954612vw, 24px);
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  line-height: 20px;
  transition: color 0.3s linear;
  color: var(--light_bg);
}
body .footer .footer_main .footer2 .contact rect {
  transition: stroke 0.3s linear;
  stroke: var(--light_bg);
}
body .footer .footer_main .footer2 .contact path {
  transition: fill 0.3s linear;
  fill: var(--light_bg);
}
body .footer .footer_main .footer2 .contact:hover {
  color: var(--base_primary);
}
body .footer .footer_main .footer2 .contact:hover rect {
  stroke: var(--base_primary);
}
body .footer .footer_main .footer2 .contact:hover path {
  fill: var(--base_primary);
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer2 .contact {
    width: 100%;
    margin: min(3.488372093vw, 15px) 0 0 0;
    gap: min(4.6511627907vw, 20px);
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam Light";
    line-height: 1;
    line-height: 16px;
  }
}
body .footer .footer_main .footer2 .contact:not(:last-child) {
  margin: 0 0 min(2.5622254758vw, 35px) 0;
}
body .footer .footer_main .footer2 .title {
  margin: 0 0 min(2.635431918vw, 36px) 0;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer2 .title {
    margin: 0 0 min(4.6511627907vw, 20px) 0;
  }
}
body .footer .footer_main .footer3 {
  width: 28%;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer3 {
    width: 100%;
    margin: min(3.488372093vw, 15px) 0 0 0;
  }
}
body .footer .footer_main .footer3 .days {
  display: flex;
  align-items: center;
  gap: min(1.6105417277vw, 22px);
  margin: 0 0 min(2.4158125915vw, 33px) 0;
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Light";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer3 .days {
    margin: 0 0 min(8.3720930233vw, 36px) 0;
    gap: min(4.6511627907vw, 20px);
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam Light";
    line-height: 1;
  }
}
body .footer .footer_main .footer3 .days svg {
  width: min(2.0497803807vw, 28px);
  height: auto;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer3 .days svg {
    width: min(4.1860465116vw, 18px);
  }
}
body .footer .footer_main .footer3 .find_txt {
  margin: 0 0 min(1.1713030747vw, 16px) 0;
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer3 .find_txt {
    display: none;
  }
}
body .footer .footer_main .footer3 .contactus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: min(2.0497803807vw, 28px);
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer3 .contactus {
    gap: min(5.8139534884vw, 25px);
    justify-content: center;
  }
}
body .footer .footer_main .footer3 .contactus svg {
  cursor: pointer;
  width: min(2.9282576867vw, 40px);
  height: auto;
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer3 .contactus svg {
    width: min(5.5813953488vw, 24px);
  }
}
body .footer .footer_main .footer3 .contactus svg rect {
  transition: stroke 0.3s linear;
  stroke: var(--light_bg);
}
body .footer .footer_main .footer3 .contactus svg path {
  transition: fill 0.3s linear;
  fill: var(--light_bg);
}
@media screen and (max-width: 900px) {
  body .footer .footer_main .footer3 .contactus svg rect {
    stroke: var(--base_primary);
  }
  body .footer .footer_main .footer3 .contactus svg path {
    fill: var(--base_primary);
  }
}
body .footer .footer_main .footer3 .contactus svg:hover rect {
  stroke: var(--base_primary);
}
body .footer .footer_main .footer3 .contactus svg:hover path {
  fill: var(--base_primary);
}
body .footer .rights {
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Light";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .footer .rights {
    display: none;
  }
}
body .section1 {
  position: relative;
}
body .section1 .section_bg_red {
  position: absolute;
  top: 0;
  right: 0;
  height: min(45.2415812592vw, 618px);
  width: min(52.9282576867vw, 723px);
}
@media screen and (max-width: 700px) {
  body .section1 .section_bg_red {
    display: none;
  }
}
body .section1 .container {
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 150px;
}
@media screen and (max-width: 700px) {
  body .section1 .container {
    flex-direction: column;
    height: unset;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 50px;
  }
}
body .section1 .container .left {
  width: 46%;
  padding: 0 min(9.1508052709vw, 125px) 0 21px;
}
@media screen and (max-width: 700px) {
  body .section1 .container .left {
    width: 100%;
    text-align: center;
  }
}
body .section1 .container .left .txt1,
body .section1 .container .left .txt2 {
  font-size: min(2.635431918vw, 36px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  line-height: 127%;
}
@media screen and (max-width: 700px) {
  body .section1 .container .left .txt1,
  body .section1 .container .left .txt2 {
    font-size: min(6.0465116279vw, 26px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
    margin: 0 0 28px 0;
    line-height: 1.45 !important;
  }
}
body .section1 .container .left .txt1 .txt2_mobile,
body .section1 .container .left .txt2 .txt2_mobile {
  display: none;
}
@media screen and (max-width: 550px) {
  body .section1 .container .left .txt1 .txt2_mobile,
  body .section1 .container .left .txt2 .txt2_mobile {
    display: inline;
    color: var(--base_primary);
  }
}
body .section1 .container .left .txt2 {
  color: var(--base_primary);
  margin: 0 0 28px 0;
}
@media screen and (max-width: 550px) {
  body .section1 .container .left .txt2 {
    display: none;
  }
}
body .section1 .container .left .txt3 {
  margin: 16px 0 30px 0;
  position: relative;
  font-size: min(1.756954612vw, 24px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  line-height: 1.459 !important;
}
@media screen and (max-width: 700px) {
  body .section1 .container .left .txt3 {
    font-size: min(4.1860465116vw, 18px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
    line-height: 1.459 !important;
  }
}
@media screen and (max-width: 700px) {
  body .section1 .container .left .book-a-call {
    display: none;
  }
}
body .section1 .container .right {
  width: 53%;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}
body .section1 .container .right iframe {
  border-radius: 20px;
  z-index: 10;
  position: relative;
}
@media screen and (max-width: 700px) {
  body .section1 .container .right {
    width: 100%;
  }
  body .section1 .container .right .cust-video-iframe {
    width: 100%;
  }
}
body .section1 .container .right .wand_img {
  position: absolute;
  right: 99%;
  top: 85%;
}
@media screen and (max-width: 700px) {
  body .section1 .container .right .wand_img {
    display: none;
  }
}
body .section1 .container .bookcall_mobile {
  display: none;
}
@media screen and (max-width: 700px) {
  body .section1 .container .bookcall_mobile {
    display: block;
    margin: min(6.976744186vw, 30px) auto 0 auto;
  }
}
body .section1_1 .container {
  padding: 0;
  padding: min(7.3206442167vw, 100px) 0;
}
body .section1_1 .container.section1_1 {
  text-align: center;
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  width: min(52.3426061493vw, 715px);
  line-height: 127.6%;
}
body .section1_1 .container.section1_1 .txt {
  margin: 0 0 min(6.5vw, 88.79px) 0;
}
@media screen and (max-width: 700px) {
  body .section1_1 .container.section1_1 .txt {
    margin: 0 0 min(20.6488372093vw, 88.79px) 0;
  }
}
body .section1_1 .container.section1_1 .svg_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .section1_1 .container.section1_1 .svg_group svg {
  cursor: pointer;
  height: auto;
}
body .section1_1 .container.section1_1 .svg_group svg:first-child {
  width: min(14.3484626647vw, 196px);
}
@media screen and (max-width: 700px) {
  body .section1_1 .container.section1_1 .svg_group svg:first-child {
    width: min(20.9302325581vw, 90px);
  }
}
body .section1_1 .container.section1_1 .svg_group img {
  cursor: pointer;
  image-rendering: smooth;
  height: 100%;
}
@media screen and (max-width: 700px) {
  body .section1_1 .container.section1_1 .svg_group img {
    width: min(20.9302325581vw, 90px);
  }
}
@media screen and (max-width: 700px) {
  body .section1_1 .container.section1_1 {
    width: min(79.3023255814vw, 341px);
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
body .section1_1 .container.section1_1 .show_app {
  position: fixed;
  inset: 0;
  z-index: 100;
  background: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
body .section1_1 .container.section1_1 .show_app .book-a-call {
  margin: min(0.7320644217vw, 10px) min(0.7320644217vw, 10px) min(0.7320644217vw, 10px) auto;
}
@media screen and (max-width: 700px) {
  body .section1_1 .container.section1_1 .show_app .book-a-call {
    margin: min(0.7320644217vw, 10px) min(0.7320644217vw, 10px) min(0.7320644217vw, 10px) auto;
  }
}
body .section1_1 .container.section1_1 .show_app iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
body .section2 {
  padding: min(10.980966325vw, 150px) 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 700px) {
  body .section2 {
    height: unset;
  }
}
@media screen and (max-width: 700px) {
  body .section2 .container {
    padding: min(11.6279069767vw, 50px) min(7.3206442167vw, 100px) 0 min(7.3206442167vw, 100px) !important;
  }
}
body .section2 .container .section2_title {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 800px) {
  body .section2 .container .section2_title {
    font-size: min(6.0465116279vw, 26px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }
}
body .section2 .container .section2_title svg {
  width: min(5.0512445095vw, 69px);
  height: min(4.5387994143vw, 62px);
}
@media screen and (max-width: 800px) {
  body .section2 .container .section2_title svg {
    width: min(7.3206442167vw, 100px);
    height: min(7.3206442167vw, 100px);
  }
}
@media screen and (max-width: 600px) {
  body .section2 .container .section2_title svg {
    width: min(16.511627907vw, 71px);
    height: min(10.2325581395vw, 44px);
  }
}
body .section2 .container .section2_title .mobile_flying_ball {
  display: none;
}
@media screen and (max-width: 600px) {
  body .section2 .container .section2_title .mobile_flying_ball {
    display: block;
    position: absolute;
    top: calc(100% - min(2.3255813953vw, 10px));
    left: calc(100% - min(4.6511627907vw, 20px));
    width: min(16.511627907vw, 71px);
  }
}
@media screen and (max-width: 600px) and (max-width: 600px) {
  body .section2 .container .section2_title .mobile_flying_ball svg {
    width: min(9.7674418605vw, 42px);
    height: min(6.0465116279vw, 26px);
    transform: translateX(10px);
  }
}
body .section2 .container .section2_title .sup {
  position: absolute;
  left: calc(100% - min(0.7320644217vw, 10px));
  bottom: calc(100% - min(0.7320644217vw, 10px));
}
@media screen and (max-width: 600px) {
  body .section2 .container .section2_title .sup {
    left: calc(100% - min(2.3255813953vw, 10px));
    bottom: calc(100% - min(2.3255813953vw, 10px));
  }
}
body .section2 .container .section2_title .sup svg {
  width: min(5.0512445095vw, 69px);
  height: min(4.5387994143vw, 62px);
}
@media screen and (max-width: 800px) {
  body .section2 .container .section2_title .sup svg {
    width: min(7.3206442167vw, 100px);
    height: min(7.3206442167vw, 100px);
  }
}
@media screen and (max-width: 600px) {
  body .section2 .container .section2_title .sup svg {
    width: min(16.511627907vw, 71px);
    height: min(10.2325581395vw, 44px);
  }
}
body .section2 .container .golden_snitches {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: min(3.6603221083vw, 50px) 0 0 0;
}
@media screen and (max-width: 800px) {
  body .section2 .container .golden_snitches {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  body .section2 .container .golden_snitches {
    margin: min(13.9534883721vw, 60px) 0 0 0;
  }
}
body .section2 .container .golden_snitches .experience_detail {
  width: 38%;
  height: min(23.5724743777vw, 322px);
  border-radius: 20px;
  background: linear-gradient(91deg, rgba(33, 31, 38, 0.79) 0%, rgba(29, 25, 30, 0) 100%);
  display: flex;
  justify-content: center;
  padding: 0 0 0 min(7.906295754vw, 108px);
  flex-direction: column;
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section2 .container .golden_snitches .experience_detail {
    padding: 0 0 0 min(4.39238653vw, 60px);
  }
}
@media screen and (max-width: 800px) {
  body .section2 .container .golden_snitches .experience_detail {
    width: 100%;
    align-items: center;
    padding: 0;
    height: min(36.6032210835vw, 500px);
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
    background: linear-gradient(180deg, rgba(33, 31, 38, 0.79) 0%, rgba(29, 25, 30, 0) 100%);
  }
}
@media screen and (max-width: 500px) {
  body .section2 .container .golden_snitches .experience_detail {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
  }
}
@media screen and (max-width: 600px) {
  body .section2 .container .golden_snitches .experience_detail {
    height: -moz-fit-content;
    height: fit-content;
    padding: min(10vw, 43px) 0 min(19.3023255814vw, 83px) 0;
  }
}
body .section2 .container .golden_snitches .experience_detail .exp_year {
  font-size: min(5.1244509517vw, 70px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  color: var(--base_primary);
  margin: 0 0 min(1.8301610542vw, 25px) min(1.4641288433vw, 20px);
}
@media screen and (max-width: 800px) {
  body .section2 .container .golden_snitches .experience_detail .exp_year {
    font-size: min(11.6279069767vw, 50px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section2 .container .golden_snitches .golden_right {
  display: flex;
  gap: 26px 24px;
  flex-wrap: wrap;
  width: 60%;
  margin: 0 0 0 -100px;
}
@media screen and (max-width: 800px) {
  body .section2 .container .golden_snitches .golden_right {
    width: 90%;
    margin: -30px auto 0 auto;
    gap: 26px;
  }
}
body .section2 .container .golden_snitches .golden_right .global_right_box {
  width: calc(50% - 12px);
  height: min(9.224011713vw, 126px);
  background-color: var(--haiti);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
}
@media screen and (max-width: 800px) {
  body .section2 .container .golden_snitches .golden_right .global_right_box {
    width: calc(50% - 14px);
    height: min(21.9619326501vw, 300px);
  }
}
@media screen and (max-width: 600px) {
  body .section2 .container .golden_snitches .golden_right .global_right_box {
    height: min(23.2558139535vw, 100px);
  }
}
@media screen and (max-width: 500px) {
  body .section2 .container .golden_snitches .golden_right .global_right_box {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
  }
}
body .section2 .container .golden_snitches .golden_right .global_right_box .global_box_count {
  font-size: min(2.635431918vw, 36px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  color: var(--base_primary);
  margin: 0 0 12px 0;
}
@media screen and (max-width: 800px) {
  body .section2 .container .golden_snitches .golden_right .global_right_box .global_box_count {
    font-size: min(6.511627907vw, 28px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section2 .container .brand_collection {
  margin: min(5.6368960469vw, 77px) 0 min(4.5387994143vw, 62px) 0;
}
@media screen and (max-width: 600px) {
  body .section2 .container .brand_collection {
    margin: min(12.5581395349vw, 54px) 0 min(9.3023255814vw, 40px) 0;
  }
}
body .section2 .container .brand_collection img {
  height: min(5.8565153734vw, 80px);
  aspect-ratio: 2/2;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
}
@media screen and (max-width: 700px) {
  body .section2 .container .brand_collection img {
    height: min(8.78477306vw, 120px);
  }
}
@media screen and (max-width: 600px) {
  body .section2 .container .brand_collection img {
    height: min(10.4651162791vw, 45px);
  }
}
body .section2 .container .section2_btn {
  margin: auto;
}
body .section3 {
  margin: min(5.1244509517vw, 70px) 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 700px) {
  body .section3 {
    height: unset;
    margin: min(34.8837209302vw, 150px) 0 0 0;
  }
}
body .section3 .container .setion3_title {
  text-align: center;
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  margin: 0 0 min(2.7086383602vw, 37px) 0;
}
body .section3 .container .setion3_title .section3_title_mobile {
  display: none;
}
@media screen and (max-width: 800px) {
  body .section3 .container .setion3_title {
    font-size: min(6.0465116279vw, 26px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
    margin: 0 0 min(8.6046511628vw, 37px) 0;
  }
  body .section3 .container .setion3_title .section3_title_desktop {
    display: none;
  }
  body .section3 .container .setion3_title .section3_title_mobile {
    display: block;
  }
}
body .section3 .container .comments {
  position: relative;
}
body .section3 .container .comments .star_img {
  position: absolute;
  bottom: 92%;
  left: 0%;
  z-index: 1;
  height: min(5.8565153734vw, 80px);
  width: min(5.8565153734vw, 80px);
}
@media screen and (max-width: 800px) {
  body .section3 .container .comments .star_img {
    height: 50px;
    width: 50px;
  }
}
@media screen and (max-width: 600px) {
  body .section3 .container .comments .star_img {
    top: -4%;
    height: min(11.6279069767vw, 50px);
    width: min(11.6279069767vw, 50px);
  }
}
body .section3 .container .comments .slick-slider .slick-list {
  padding: 0 !important;
}
body .section3 .container .comments .slick-slider .slick-list .slick-track {
  display: flex;
  margin: 0;
}
body .section3 .container .comments .slick-slider .slick-list .slick-track .slick-slide {
  height: unset;
  align-self: stretch;
}
body .section3 .container .comments .slick-slider .slick-list .slick-track .slick-slide > div {
  height: 100%;
}
body .section3 .container .comments .slick-slider .slick-list .slick-track .slick-slide > div .inner_commnet {
  height: 100%;
}
body .section3 .container .comments .slick-slider .slick-list .slick-track .slick-slide > div .inner_commnet .making_gap {
  height: 100%;
}
body .section3 .container .comments .inner_commnet {
  padding: 0 min(1.4641288433vw, 20px);
}
@media screen and (max-width: 600px) {
  body .section3 .container .comments .inner_commnet {
    padding: 0 min(2.3255813953vw, 10px);
  }
}
body .section3 .container .comments .inner_commnet .making_gap {
  background-color: var(--haiti);
  border-radius: 20px;
  display: flex !important;
  gap: min(9.3704245974vw, 128px);
  padding: min(3.6603221083vw, 50px) min(6.7349926794vw, 92px) min(3.6603221083vw, 50px) min(4.0263543192vw, 55px);
}
@media screen and (max-width: 700px) {
  body .section3 .container .comments .inner_commnet .making_gap {
    padding: 0;
  }
}
body .section3 .container .comments .inner_commnet .making_gap .inner_left {
  width: 70%;
}
@media screen and (max-width: 700px) {
  body .section3 .container .comments .inner_commnet .making_gap .inner_left {
    width: 100%;
    padding: min(5.8139534884vw, 25px) min(6.0465116279vw, 26px);
  }
}
body .section3 .container .comments .inner_commnet .making_gap .inner_left > .client_detail {
  display: flex;
  align-items: center;
  gap: 28px;
  padding: 0 0 min(2.9282576867vw, 40px) 0;
}
body .section3 .container .comments .inner_commnet .making_gap .inner_left > .client_detail > img {
  height: auto;
  width: min(7.5402635432vw, 103px);
  border-radius: 50%;
}
@media screen and (max-width: 700px) {
  body .section3 .container .comments .inner_commnet .making_gap .inner_left > .client_detail > img {
    width: min(23.9534883721vw, 103px);
  }
}
body .section3 .container .comments .inner_commnet .making_gap .inner_left > .client_detail .client_name {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
}
@media screen and (max-width: 800px) {
  body .section3 .container .comments .inner_commnet .making_gap .inner_left > .client_detail .client_name {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
  }
}
body .section3 .container .comments .inner_commnet .making_gap .inner_left > .client_detail .client_name .clientdesc_1 {
  font-size: min(1.6105417277vw, 22px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
}
@media screen and (max-width: 800px) {
  body .section3 .container .comments .inner_commnet .making_gap .inner_left > .client_detail .client_name .clientdesc_1 {
    font-size: min(5.1162790698vw, 22px);
    font-family: "Be Vietnam ExtraBold";
    line-height: 1;
  }
}
body .section3 .container .comments .inner_commnet .making_gap .inner_left .desc,
body .section3 .container .comments .inner_commnet .making_gap .inner_left .solving_status {
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  line-height: 1.5 !important;
}
@media screen and (max-width: 800px) {
  body .section3 .container .comments .inner_commnet .making_gap .inner_left .desc,
  body .section3 .container .comments .inner_commnet .making_gap .inner_left .solving_status {
    font-size: min(4.1860465116vw, 18px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 600px) {
  body .section3 .container .comments .inner_commnet .making_gap .inner_left .desc,
  body .section3 .container .comments .inner_commnet .making_gap .inner_left .solving_status {
    line-height: 2 !important;
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
  }
}
body .section3 .container .comments .inner_commnet .making_gap .inner_left .solving_status {
  margin: 20px 0 0 0;
}
body .section3 .container .comments .inner_commnet .making_gap .inner_right {
  width: 30%;
}
@media screen and (max-width: 700px) {
  body .section3 .container .comments .inner_commnet .making_gap .inner_right {
    display: none;
  }
}
body .section4 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: min(10.980966325vw, 150px) 0 min(10.980966325vw, 150px) 0;
}
@media screen and (max-width: 700px) {
  body .section4 {
    margin: min(34.8837209302vw, 150px) 0 min(34.8837209302vw, 150px) 0;
    height: unset;
  }
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box {
    padding: 0 min(2.3255813953vw, 10px);
  }
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 0 min(7.3206442167vw, 100px);
  padding: 50px 0;
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 {
    flex-direction: column;
    padding: 0px 0;
  }
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left {
  width: min(31.8448023426vw, 435px);
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left {
    width: 100%;
  }
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_title {
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  margin: 0 0 min(0.878477306vw, 12px) 0;
}
@media screen and (max-width: 900px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_title {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_title {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
    text-align: center;
    margin: 0 0 min(4.6511627907vw, 20px) 0;
  }
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_title span {
    color: var(--base_primary);
  }
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_title span {
  color: var(--base_primary);
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_desc {
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  line-height: 1.455 !important;
  margin: 0 0 min(5.710102489vw, 78px) 0;
}
@media screen and (max-width: 900px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_desc {
    font-size: min(4.1860465116vw, 18px);
    font-family: "Be Vietnam Light";
    line-height: 1;
  }
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_left_desc {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left .section4_btn {
    display: none;
  }
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left_mobile {
  display: none;
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .secton4_left_mobile {
    display: block;
    text-align: center;
    margin: min(6.976744186vw, 30px) 0 0 0;
  }
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .section4_box_img {
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .section4_box_img iframe {
  border-radius: 20px;
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .section4_box_img .top_leftbox {
  position: absolute;
  top: min(-2.2693997072vw, -31px);
  left: min(-1.8301610542vw, -25px);
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .section4_box_img .top_leftbox {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .section4_box_img .section4_video {
    width: 100%;
  }
}
body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .section4_box_img .bottom_rightbox {
  position: absolute;
  bottom: min(-2.5622254758vw, -35px);
  right: min(-1.4641288433vw, -20px);
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_box .make_gap_secttion4 .section4_box_img .bottom_rightbox {
    display: none;
  }
}
body .section4 .container .inner_section4 .section4_btn_mob {
  display: none;
}
@media screen and (max-width: 700px) {
  body .section4 .container .inner_section4 .section4_btn_mob {
    display: block;
    display: flex;
    justify-content: center;
    margin: min(9.3023255814vw, 40px) auto 0 auto;
  }
}
body :root {
  --scroll: 0;
}
body .section5 {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 700px) {
  body .section5 {
    height: unset;
  }
}
body .section5 .container {
  color: var(--font_white_color);
}
@media screen and (max-width: 700px) {
  body .section5 .container {
    padding: unset;
  }
}
body .section5 .container .main2_section5 {
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  overflow-y: hidden;
}
body .section5 .container .main2_section5 .show_desktop {
  display: block;
}
@media screen and (max-width: 700px) {
  body .section5 .container .main2_section5 .show_desktop {
    display: none;
  }
}
body .section5 .container .main2_section5 .show_mobile {
  display: none;
}
@media screen and (max-width: 700px) {
  body .section5 .container .main2_section5 .show_mobile {
    display: block;
  }
}
body .section5 .container .main2_section5 img {
  width: 100%;
}
@media screen and (max-width: 700px) {
  body .section5 .container .main2_section5 {
    padding: 0;
  }
}
body .section5 .container .main2_section5 .inner_section5 {
  background-color: var(--base_primary2);
  border-radius: 20px;
  gap: 0 64px;
  height: min(18.8872620791vw, 258px);
  padding: min(3.6603221083vw, 50px) min(3.8799414348vw, 53px);
}
@media screen and (max-width: 650px) {
  body .section5 .container .main2_section5 .inner_section5 {
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media screen and (max-width: 600px) {
  body .section5 .container .main2_section5 .inner_section5 {
    height: -moz-fit-content;
    height: fit-content;
    padding: min(6.976744186vw, 30px);
  }
}
body .section5 .container .main2_section5 .inner_section5 .section5_title_mobile {
  display: none;
}
@media screen and (max-width: 600px) {
  body .section5 .container .main2_section5 .inner_section5 .section5_title_mobile {
    display: block;
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
body .section5 .container .main2_section5 .inner_section5 .section5_box {
  width: min(46.925329429vw, 641px);
}
@media screen and (max-width: 600px) {
  body .section5 .container .main2_section5 .inner_section5 .section5_box {
    display: flex;
    align-items: center;
    width: unset;
  }
}
body .section5 .container .main2_section5 .inner_section5 .section5_box .section5_title {
  font-size: min(2.2693997072vw, 31px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  margin: 0 0 min(1.317715959vw, 18px) 0;
}
@media screen and (max-width: 650px) {
  body .section5 .container .main2_section5 .inner_section5 .section5_box .section5_title {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
@media screen and (max-width: 600px) {
  body .section5 .container .main2_section5 .inner_section5 .section5_box .section5_title {
    display: none;
  }
}
body .section5 .container .main2_section5 .inner_section5 .section5_box .section5_desc {
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  line-height: 1.5;
}
body .section5 .container .main2_section5 .inner_section5 .section5_box .section5_desc span {
  font-size: min(4.6511627907vw, 20px);
  font-family: "Be Vietnam ExtraBold";
  line-height: 1;
}
@media screen and (max-width: 650px) {
  body .section5 .container .main2_section5 .inner_section5 .section5_box .section5_desc {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
  }
}
@media screen and (max-width: 600px) {
  body .section5 .container .main2_section5 .inner_section5 .section5_box .section5_desc {
    margin: min(6.976744186vw, 30px) 0 0 0;
    line-height: 1.4;
  }
}
body .section5 .container .main2_section5 .inner_section5 .section5_box .rocket_mobile {
  display: none;
}
body .section5 .container .main2_section5 .inner_section5 .section5_box .rocket_mobile svg {
  height: min(42.7906976744vw, 184px);
  width: min(35.8139534884vw, 154px);
}
@media screen and (max-width: 600px) {
  body .section5 .container .main2_section5 .inner_section5 .section5_box .rocket_mobile {
    display: block;
    transform: translateX(25px) translateY(40px);
  }
}
body .section5 .container .main2_section5 .inner_section5 .rocket {
  position: absolute;
  right: 0;
  bottom: -2%;
}
body .section5 .container .main2_section5 .inner_section5 .rocket > div {
  transition: transform 0.2s ease-in-out;
}
body .section5 .container .main2_section5 .inner_section5 .rocket svg {
  height: min(32.2840409956vw, 441px);
  width: min(29.9414348463vw, 409px);
}
@media screen and (max-width: 600px) {
  body .section5 .container .main2_section5 .inner_section5 .rocket {
    position: unset;
    display: none;
  }
}
body .section5 .container .section5_btn {
  margin: min(4.1727672035vw, 57px) auto min(7.906295754vw, 108px) auto;
}
@media screen and (max-width: 650px) {
  body .section5 .container .section5_btn {
    margin: min(9.3023255814vw, 40px) auto 0 auto;
  }
}
body .section6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: min(7.3206442167vw, 100px) 0 0 0;
}
@media screen and (max-width: 700px) {
  body .section6 {
    height: unset;
    padding: 150px 0 0 0;
  }
}
body .section6 .container {
  overflow: hidden;
}
body .section6 .container .inner_section6 {
  color: var(--font_white_color);
}
body .section6 .container .inner_section6 .team_group {
  margin: 40px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 44px;
}
body .section6 .container .inner_section6 .team_group img {
  width: calc(25% - 33px);
}
@media screen and (max-width: 600px) {
  body .section6 .container .inner_section6 .team_group {
    gap: 22px;
    overflow: auto;
    justify-content: start;
  }
  body .section6 .container .inner_section6 .team_group img {
    width: 60%;
  }
}
body .section6 .container .inner_section6 .section6_moto {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
body .section6 .container .inner_section6 .section6_moto .section6_moto_title {
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
}
@media screen and (max-width: 600px) {
  body .section6 .container .inner_section6 .section6_moto .section6_moto_title {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
body .section6 .container .inner_section6 .section6_moto .section6_moto_inner_title {
  text-align: center;
  margin: 10px 0 0 0;
  font-size: min(1.2445095168vw, 17px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
}
@media screen and (max-width: 600px) {
  body .section6 .container .inner_section6 .section6_moto .section6_moto_inner_title {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
  }
}
body .section6 .container .inner_section6 .team_item_main {
  width: -moz-fit-content;
  width: fit-content;
  margin: min(4.39238653vw, 60px) 0 0 0;
  padding: 0 min(0.7320644217vw, 10px);
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: min(2.9282576867vw, 40px);
  border-bottom-right-radius: min(2.9282576867vw, 40px);
}
body .section6 .container .inner_section6 .team_item_main .img {
  border-radius: 20px;
  background: var(--team_bg);
  width: 100%;
  height: min(24.1581259151vw, 330px);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  overflow: hidden;
}
body .section6 .container .inner_section6 .team_item_main .team_item {
  height: 100%;
  width: 100%;
  background: var(--white);
  border-radius: min(1.4641288433vw, 20px);
}
body .section6 .container .inner_section6 .team_item_main .item_description {
  --diff: min(0.7320644217vw, 10px);
  --bottom_desc: 0;
  position: absolute;
  left: var(--diff);
  right: var(--diff);
  bottom: calc(var(--bottom_desc) * -1px - min(2.4890190337vw, 34px));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  padding: 0 min(0.878477306vw, 12px) min(1.4641288433vw, 20px) min(1.4641288433vw, 20px);
  transition: bottom 0.3s linear;
}
body .section6 .container .inner_section6 .team_item_main .item_description .name {
  margin: min(14.494875549vw, 198px) 0 min(0.5856515373vw, 8px) 0;
  font-size: min(1.6105417277vw, 22px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
}
body .section6 .container .inner_section6 .team_item_main .item_description .designation {
  font-size: min(1.0248901903vw, 14px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  margin: 0 0 min(1.9033674963vw, 26px) 0;
  border-radius: min(1.4641288433vw, 20px);
}
body .section6 .container .inner_section6 .team_item_main .item_description .description {
  font-size: min(1.0248901903vw, 14px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  border-radius: min(1.4641288433vw, 20px);
}
body .section6 .container .inner_section6 .team_item_main:hover .item_description {
  bottom: 0;
  border-radius: min(1.4641288433vw, 20px);
}
body .section6 .container .inner_section6 .team_mobile .team_mobile_slick {
  width: min(67.4418604651vw, 290px);
}
body .section6 .container .inner_section6 .team_mobile .team_mobile_slick .slick-list {
  overflow: unset;
}
body .section6 .container .inner_section6 .team_mobile .slick-slide {
  overflow: unset !important;
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile {
  aspect-ratio: 266/300;
  margin: min(4.6511627907vw, 20px) 0 0 0;
  padding: 0 min(2.3255813953vw, 10px);
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: min(8.8372093023vw, 38px);
  border-bottom-right-radius: min(8.8372093023vw, 38px);
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile .img {
  border-radius: 20px;
  background: var(--dots);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  overflow: hidden;
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile .team_ite {
  height: 100%;
  width: 100%;
  background: var(--white);
  border-radius: min(1.4641288433vw, 20px);
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile .item_description {
  --diff: min(2.3255813953vw, 10px);
  --bottom_desc: 0;
  position: absolute;
  left: var(--diff);
  right: var(--diff);
  bottom: calc(var(--bottom_desc) * -1px - min(7.9069767442vw, 34px));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: min(4.6511627907vw, 20px);
  padding: 0 min(2.7906976744vw, 12px) min(4.6511627907vw, 20px) min(4.6511627907vw, 20px);
  transition: bottom 0.3s linear;
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile .item_description .name_mob {
  margin: min(46.0465116279vw, 198px) 0 0 0;
  font-size: min(4.1860465116vw, 18px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile .item_description .designation_mob {
  font-size: min(3.2558139535vw, 14px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  margin: 0 0 min(6.0465116279vw, 26px) 0;
  border-radius: min(4.6511627907vw, 20px);
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile .item_description .description_mob {
  font-size: min(3.2558139535vw, 14px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  border-radius: min(4.6511627907vw, 20px);
}
body .section6 .container .inner_section6 .team_mobile .team_item_main_mobile:hover .item_description {
  bottom: 0;
  border-radius: min(1.4641288433vw, 20px);
}
body .section6 .container .inner_section6 .section6_desc {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  padding: min(3.8067349927vw, 52px) min(3.6603221083vw, 50px);
  background-color: var(--haiti);
  width: min(56.2957540264vw, 769px);
  margin: min(5.1244509517vw, 70px) auto 0 auto;
  border-radius: 20px;
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
  line-height: 1.455 !important;
}
@media screen and (max-width: 600px) {
  body .section6 .container .inner_section6 .section6_desc {
    display: none;
  }
}
body .section6 .container .inner_section6 .section6_desc svg {
  height: min(4.6852122987vw, 64px);
  width: min(4.6852122987vw, 64px);
}
body .section6 .container .inner_section6 .section6_desc span {
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
}
body .section6 .container .inner_section6 .section6_desc_mobile {
  display: none;
  margin: min(8.6046511628vw, 37px) 0 0 0;
}
@media screen and (max-width: 600px) {
  body .section6 .container .inner_section6 .section6_desc_mobile {
    display: block;
  }
}
body .section6 .container .inner_section6 .section6_desc_mobile .section6_desc1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(3.9534883721vw, 17px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  color: var(--base_primary);
}
body .section6 .container .inner_section6 .section6_desc_mobile .section6_desc2 {
  text-align: center;
  margin: min(3.2558139535vw, 14px) 0 0 0;
  font-size: min(3.7209302326vw, 16px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  line-height: 1.3;
}
body .section6 .container .inner_section6 .section6_btn {
  margin: min(3.8799414348vw, 53px) auto 0 auto;
}
@media screen and (max-width: 600px) {
  body .section6 .container .inner_section6 .section6_btn {
    margin: min(6.976744186vw, 30px) auto 0 auto;
  }
}
body .section7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: min(13.9092240117vw, 190px) 0 0 0;
}
@media screen and (max-width: 700px) {
  body .section7 {
    padding: 150px 0 0 0;
    min-height: unset;
  }
}
body .section7 .container .inner_section7 img {
  width: 100%;
}
@media screen and (max-width: 600px) {
  body .section7 .container .inner_section7 {
    display: none;
  }
}
body .section7 .container .inner_section7 .section7_title {
  text-align: center;
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section7 .container .inner_section7 .section7_title {
    font-size: min(6.0465116279vw, 26px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
body .section7 .container .inner_section7 .section7_items {
  display: flex;
  flex-direction: column;
  gap: min(3.6603221083vw, 50px);
  margin: min(10.3221083455vw, 141px) 0 0 0;
}
body .section7 .container .inner_section7 .section7_items .items {
  width: min(27.1595900439vw, 371px);
  position: relative;
}
@media screen and (max-width: 900px) {
  body .section7 .container .inner_section7 .section7_items .items {
    width: min(58.1395348837vw, 250px);
  }
}
body .section7 .container .inner_section7 .section7_items .items .item_image {
  position: absolute;
  top: min(-1.0980966325vw, -15px);
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(odd) .item_image {
  right: calc(100% + min(1.8301610542vw, 25px));
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(even) {
  margin: 0 0 0 auto;
  text-align: end;
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(even) .item_image {
  position: absolute;
  left: calc(100% + min(1.8301610542vw, 25px));
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(1) .item_image svg {
  width: min(7.027818448vw, 96px);
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(2) .item_image svg {
  width: min(10.4685212299vw, 143px);
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(3) .item_image svg {
  width: min(8.345534407vw, 114px);
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(4) .item_image svg {
  width: min(7.7598828697vw, 106px);
}
body .section7 .container .inner_section7 .section7_items .items:nth-child(5) .item_image svg {
  width: min(9.3704245974vw, 128px);
}
body .section7 .container .inner_section7 .section7_items .items .item_image svg {
  height: auto;
}
body .section7 .container .inner_section7 .section7_items .items .items_name {
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  margin: 0 0 min(0.5856515373vw, 8px) 0;
}
@media screen and (max-width: 900px) {
  body .section7 .container .inner_section7 .section7_items .items .items_name {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
    margin: 0 0 min(1.8604651163vw, 8px) 0;
  }
}
body .section7 .container .inner_section7 .section7_items .items .item_translate {
  font-size: min(1.6105417277vw, 22px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
  margin: 0 0 min(0.878477306vw, 12px) 0;
}
@media screen and (max-width: 900px) {
  body .section7 .container .inner_section7 .section7_items .items .item_translate {
    margin: 0 0 min(2.7906976744vw, 12px) 0;
    font-size: min(4.1860465116vw, 18px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
  }
}
body .section7 .container .inner_section7 .section7_items .items .item_desc {
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  line-height: 1.455 !important;
}
@media screen and (max-width: 900px) {
  body .section7 .container .inner_section7 .section7_items .items .item_desc {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Light";
    line-height: 1;
    line-height: 1.455 !important;
  }
}
body .section7 .inner_section7_mobile {
  width: 100%;
  display: none;
}
@media screen and (max-width: 600px) {
  body .section7 .inner_section7_mobile {
    display: block;
  }
}
body .section7 .inner_section7_mobile .section7_title_mobile {
  font-size: min(5.5813953488vw, 24px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  text-align: center;
  margin: 0 0 min(4.6511627907vw, 20px) 0;
}
body .section7 .inner_section7_mobile .container .items_mob {
  background-color: var(--haiti);
  padding: min(5.5813953488vw, 24px) min(4.6511627907vw, 20px);
  border-radius: min(2.3255813953vw, 10px);
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 min(3.488372093vw, 15px);
  padding: 0 0 min(4.4186046512vw, 19px) 0;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header .item_image_mob_1 > svg {
  width: min(10.9302325581vw, 47px);
  height: min(13.2558139535vw, 57px);
  left: 0;
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header .item_image_mob_2 > svg {
  width: min(15.5813953488vw, 67px);
  height: min(13.2558139535vw, 57px);
  top: 0;
  right: calc(100% + min(0.7906976744vw, 3.4px));
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header .item_image_mob_3 > svg {
  width: min(19.5348837209vw, 84px);
  height: min(16.511627907vw, 71px);
  top: min(2.7674418605vw, 11.9px);
  right: calc(100% - min(0.9302325581vw, 4px));
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header .item_image_mob_4 > svg {
  width: min(11.6279069767vw, 50px);
  height: min(15.3488372093vw, 66px);
  top: min(-4.1860465116vw, -18px);
  right: 100%;
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header .item_image_mob_5 > svg {
  width: min(15.1372093023vw, 65.09px);
  height: min(20.793255814vw, 89.411px);
  top: min(-5.8139534884vw, -25px);
  right: calc(100% + min(2.3255813953vw, 10px));
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header .item_mobile_name_translate .items_name_mob {
  font-size: min(4.6511627907vw, 20px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  margin: 0 0 min(1.1627906977vw, 5px) 0;
}
body .section7 .inner_section7_mobile .container .items_mob .item_mobile_header .item_mobile_name_translate .item_translate_mob {
  font-size: min(4.1860465116vw, 18px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
}
body .section7 .inner_section7_mobile .container .items_mob .item_desc_data_mob {
  display: flex;
  gap: 0 18px;
}
body .section7 .inner_section7_mobile .container .items_mob .item_desc_data_mob .item_desc_mob {
  font-size: min(3.2558139535vw, 14px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  line-height: 1.4;
  z-index: 1;
}
body .section7 .inner_section7_mobile .container .items_mob .item_desc_data_mob .item_image_mob {
  background-color: white;
  padding: 10px 10px 30px 10px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 2px;
}
body .section8 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: min(10.980966325vw, 150px) 0 min(10.980966325vw, 150px) 0;
}
@media screen and (max-width: 700px) {
  body .section8 {
    height: unset;
    margin: min(34.8837209302vw, 150px) 0 min(34.8837209302vw, 150px) 0;
  }
}
body .section8 .container .inner_section8 {
  margin: min(9.1508052709vw, 125px) 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 700px) {
  body .section8 .container .inner_section8 {
    margin: 0 !important;
  }
}
@media screen and (max-width: 900px) {
  body .section8 .container .inner_section8 {
    margin: min(29.0697674419vw, 125px) 0 0 0;
  }
}
body .section8 .container .inner_section8 .section8_title {
  font-size: min(5.1244509517vw, 70px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  font-family: "Harry P";
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
@media screen and (max-width: 900px) {
  body .section8 .container .inner_section8 .section8_title {
    font-size: min(9.3023255814vw, 40px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
    font-family: "Harry P";
  }
}
body .section8 .container .inner_section8 .section8_title img {
  width: min(10.1756954612vw, 139px);
  height: min(6.8081991215vw, 93px);
  position: absolute;
  bottom: calc(100% + min(-2.196193265vw, -30px));
  left: calc(100% + min(-2.196193265vw, -30px));
}
@media screen and (max-width: 900px) {
  body .section8 .container .inner_section8 .section8_title img {
    width: min(19.0697674419vw, 82px);
    height: min(12.7906976744vw, 55px);
    bottom: calc(100% - 20px);
    left: calc(100% - 20px);
  }
}
body .section8 .container .inner_section8 .section8_title_sub {
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  margin: min(0.5856515373vw, 8px) 0 0 0;
}
@media screen and (max-width: 900px) {
  body .section8 .container .inner_section8 .section8_title_sub {
    margin: min(2.3255813953vw, 10px) 0 0 0;
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section8 .container .inner_section8 .section8_desc {
  width: min(48.4626647145vw, 662px);
  text-align: center;
  font-size: min(1.6105417277vw, 22px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  color: rgba(255, 255, 255, 0.88);
  line-height: 1.5;
  margin: min(1.4641288433vw, 20px) 0 0 0;
}
@media screen and (max-width: 900px) {
  body .section8 .container .inner_section8 .section8_desc {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
    line-height: 1.5;
    margin: min(4.6511627907vw, 20px) 0 0 0;
  }
}
@media screen and (max-width: 900px) {
  body .section8 .container .inner_section8 .section8_desc {
    width: unset;
  }
}
body .section8 .container .inner_section8 .section8_btn {
  margin: min(2.9282576867vw, 40px) 0 0 0;
}
@media screen and (max-width: 900px) {
  body .section8 .container .inner_section8 .section8_btn {
    margin: min(9.3023255814vw, 40px) 0 0 0;
  }
}
body .section9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: min(10.980966325vw, 150px) 0 min(10.980966325vw, 150px) 0;
  margin: 0;
}
@media screen and (max-width: 700px) {
  body .section9 {
    padding: min(34.8837209302vw, 150px) 0 min(34.8837209302vw, 150px) 0;
    padding: 0 0 0 0;
    height: unset;
    margin: 150px 0 0 0;
  }
}
body .section9 .container .inner_section9 {
  color: var(--font_white_color);
}
body .section9 .container .inner_section9 .section9_title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: min(2.635431918vw, 36px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section9 .container .inner_section9 .section9_title {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
body .section9 .container .inner_section9 .section9_title img {
  width: min(7.027818448vw, 96px);
  height: min(6.588579795vw, 90px);
}
@media screen and (max-width: 600px) {
  body .section9 .container .inner_section9 .section9_title img {
    display: none;
  }
}
body .section9 .container .inner_section9 .section9_title_sub {
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .section9 .container .inner_section9 .section9_title_sub {
    font-size: min(4.1860465116vw, 18px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
    margin: min(1.3953488372vw, 6px) 0 0 0;
  }
}
body .section9 .container .inner_section9 .section9_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: min(5.8565153734vw, 80px) min(13.17715959vw, 180px);
  margin: min(4.39238653vw, 60px) 0 0 0;
}
@media screen and (max-width: 900px) {
  body .section9 .container .inner_section9 .section9_items {
    gap: min(4.6511627907vw, 20px) min(4.6511627907vw, 20px);
  }
}
@media screen and (max-width: 600px) {
  body .section9 .container .inner_section9 .section9_items {
    display: none;
  }
}
body .section9 .container .inner_section9 .section9_items .section9_box {
  width: 40%;
  display: flex;
  gap: min(1.756954612vw, 24px);
}
@media screen and (max-width: 900px) {
  body .section9 .container .inner_section9 .section9_items .section9_box {
    width: 48%;
    gap: min(2.3255813953vw, 10px);
  }
}
body .section9 .container .inner_section9 .section9_items .section9_box .section9_box_tick svg {
  width: min(2.5622254758vw, 35px);
  height: min(2.5622254758vw, 35px);
}
@media screen and (max-width: 900px) {
  body .section9 .container .inner_section9 .section9_items .section9_box .section9_box_tick svg {
    width: min(8.1395348837vw, 35px);
    height: min(8.1395348837vw, 35px);
  }
}
body .section9 .container .inner_section9 .section9_items .section9_box .section9_title_desc .section9_box_title {
  font-size: min(1.9033674963vw, 26px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  margin: 0 0 min(0.7320644217vw, 10px) 0;
}
@media screen and (max-width: 900px) {
  body .section9 .container .inner_section9 .section9_items .section9_box .section9_title_desc .section9_box_title {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section9 .container .inner_section9 .section9_items .section9_box .section9_title_desc .section9_box_desc {
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  line-height: 1.4;
}
@media screen and (max-width: 900px) {
  body .section9 .container .inner_section9 .section9_items .section9_box .section9_title_desc .section9_box_desc {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
  }
}
body .section9 .section9_items_mobile {
  display: none;
  position: relative;
  width: 100%;
  margin: min(6.976744186vw, 30px) 0 0 0;
}
@media screen and (max-width: 600px) {
  body .section9 .section9_items_mobile {
    display: block;
  }
}
body .section9 .section9_items_mobile .hat {
  position: absolute;
  right: calc(100% - min(16.2790697674vw, 70px));
  bottom: calc(100% - min(4.6511627907vw, 20px));
  transform: rotate(-24.32deg);
  height: min(13.023255814vw, 56px);
  z-index: 10;
}
body .section9 .section9_items_mobile .container .section9_box_mobile {
  background-color: var(--haiti);
  padding: min(6.976744186vw, 30px) min(10.4651162791vw, 45px) min(6.976744186vw, 30px) min(4.8837209302vw, 21px);
  border-radius: min(2.3255813953vw, 10px);
  display: flex !important;
  gap: 10px;
}
body .section9 .section9_items_mobile .container .section9_box_mobile .section9_box_tick_mobile svg {
  height: min(6.0465116279vw, 26px);
  width: min(6.2790697674vw, 27px);
}
body .section9 .section9_items_mobile .container .section9_box_mobile .section9_title_desc_mobile .section9_box_title_mobile {
  font-size: min(4.6511627907vw, 20px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  margin: 0 0 min(2.7906976744vw, 12px) 0;
}
body .section9 .section9_items_mobile .container .section9_box_mobile .section9_title_desc_mobile .section9_box_desc_mobile {
  font-size: min(3.2558139535vw, 14px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  line-height: 1.3;
}
body .section9 .section9_btn {
  margin: min(4.39238653vw, 60px) auto 0 auto;
}
@media screen and (max-width: 600px) {
  body .section9 .section9_btn {
    margin: min(9.3023255814vw, 40px) auto 0 auto;
  }
}
body .section10 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}
@media screen and (max-width: 700px) {
  body .section10 {
    height: unset;
    margin: 150px 0 0 0;
  }
}
body .section10 .faq_title {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .section10 .faq_title > div {
  position: relative;
  margin: 0 0 min(4.39238653vw, 60px) 0;
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section10 .faq_title > div {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
    margin: 0 0 min(6.0465116279vw, 26px) 0;
  }
}
body .section10 .faq_title > div > div:nth-child(1) {
  display: none;
}
@media screen and (max-width: 900px) {
  body .section10 .faq_title > div > div:nth-child(1) {
    display: block;
  }
}
@media screen and (max-width: 900px) {
  body .section10 .faq_title > div > div:nth-child(2) {
    display: none;
  }
}
body .section10 .faq_title > div img {
  position: absolute;
  height: 130%;
  bottom: 0;
  width: auto;
  right: -30px;
}
body .section10 .accordian_main {
  background: var(--haiti);
  border-radius: 20px;
  margin: 0 0 min(1.6105417277vw, 22px) 0;
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  line-height: 1.2 !important;
}
@media screen and (max-width: 900px) {
  body .section10 .accordian_main {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam Light";
    line-height: 1;
    margin: 0 0 min(4.1860465116vw, 18px) 0;
    line-height: 1.2 !important;
  }
}
@media screen and (max-width: 600px) {
  body .section10 .accordian_main {
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam Light";
    line-height: 1;
    border-radius: 10px;
  }
}
body .section10 .accordian_main .accordian_header {
  padding: min(1.4641288433vw, 20px) min(2.9282576867vw, 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  body .section10 .accordian_main .accordian_header {
    padding: min(2.7906976744vw, 12px) min(4.6511627907vw, 20px);
  }
}
body .section10 .accordian_main .accordian_header .que {
  width: calc(100% - min(2.3426061493vw, 32px));
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section10 .accordian_main .accordian_header .que {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
    width: calc(100% - min(7.4418604651vw, 32px));
  }
}
@media screen and (max-width: 600px) {
  body .section10 .accordian_main .accordian_header .que {
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section10 .accordian_main .accordian_header .accordian_arrow {
  height: min(2.3426061493vw, 32px);
  width: min(2.3426061493vw, 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
}
@media screen and (max-width: 900px) {
  body .section10 .accordian_main .accordian_header .accordian_arrow {
    height: min(7.4418604651vw, 32px);
    width: min(7.4418604651vw, 32px);
  }
}
@media screen and (max-width: 600px) {
  body .section10 .accordian_main .accordian_header .accordian_arrow {
    height: min(5.8139534884vw, 25px);
    width: min(5.5813953488vw, 24px);
  }
}
body .section10 .accordian_main .accordian_header .accordian_arrow svg {
  width: 100%;
  height: auto;
}
body .section10 .accordian_main .accordian_body {
  overflow: hidden;
  transition: all 0.3s linear;
  line-height: 1.45 !important;
}
body .section10 .accordian_main .accordian_body > div {
  padding: 0 min(2.9282576867vw, 40px) min(2.196193265vw, 30px) min(2.9282576867vw, 40px);
}
@media screen and (max-width: 900px) {
  body .section10 .accordian_main .accordian_body > div {
    padding: 0 min(9.3023255814vw, 40px) min(6.976744186vw, 30px) min(4.6511627907vw, 20px);
  }
}
body .section10 .faq_btn {
  margin: min(3.6603221083vw, 50px) auto 20px auto;
}
@media screen and (max-width: 500px) {
  body .section10 .faq_btn {
    margin: min(11.6279069767vw, 50px) auto 20px auto;
  }
}
body .section11 {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 700px) {
  body .section11 {
    height: 70vh;
  }
}
body .section11 .container .inner_section11 {
  margin: min(18.3016105417vw, 250px) 0 min(14.6412884334vw, 200px) 0;
}
@media screen and (max-width: 700px) {
  body .section11 .container .inner_section11 {
    margin: 0;
  }
}
body .section11 .container .inner_section11 .inner_section11_title {
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11 .inner_section11_title {
    font-size: min(4.6511627907vw, 20px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
body .section11 .container .inner_section11 .inner_section11_title img {
  position: absolute;
  bottom: 100%;
  width: min(9.7364568082vw, 133px);
  height: min(7.2474377745vw, 99px);
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11 .inner_section11_title img {
    width: min(23.2558139535vw, 100px);
    height: min(17.4418604651vw, 75px);
  }
}
body .section11 .container .inner_section11 .inner_section11_subtitle {
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11 .inner_section11_subtitle {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Regular";
    line-height: 1;
  }
}
body .section11 .container .inner_section11 .inner_section11_subtitle > div {
  line-height: 1.8;
  margin: min(1.0248901903vw, 14px) 0 0 0;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11 .inner_section11_subtitle > div {
    margin: min(3.488372093vw, 15px) 0 0 0;
  }
}
body .section11 .container .inner_section11 .email_taker {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: min(2.9282576867vw, 40px);
  margin: min(5.3440702782vw, 73px) 0 0 0;
}
@media screen and (max-width: 500px) {
  body .section11 .container .inner_section11 .email_taker {
    margin: min(14.8837209302vw, 64px) 0 0 0;
    gap: min(7.2093023256vw, 31px);
  }
}
body .section11 .container .inner_section11 .email_taker .cust-input {
  width: min(21.7423133236vw, 297px);
  border-bottom: solid 1px var(--font_white_color);
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11 .email_taker .cust-input {
    width: min(49.5348837209vw, 213px);
    height: min(9.3023255814vw, 40px);
  }
}
body .section11 .container .inner_section11 .email_taker .cust-input input {
  width: 100%;
  height: 100%;
  outline: 0;
  background-color: transparent;
  color: var(--font_white_color);
  border: none;
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Regular";
  line-height: 1;
  padding: min(1.0248901903vw, 14px) min(1.0248901903vw, 14px) min(1.0248901903vw, 14px) 0;
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11 .email_taker .cust-input input {
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
    padding: min(3.023255814vw, 13px) min(3.023255814vw, 13px) min(3.023255814vw, 13px) 0;
  }
}
body .section11 .container .inner_section11 .email_taker .error {
  border-bottom: solid 1px red;
}
body .section11 .container .inner_section11 .section11_desc {
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
  line-height: 1.45 !important;
  width: min(41.654465593vw, 569px);
  text-align: center;
  margin: min(3.0014641288vw, 41px) auto 0 auto;
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11 .section11_desc {
    width: min(72.7906976744vw, 313px);
    font-size: min(2.7906976744vw, 12px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
    line-height: 1.45 !important;
    margin: min(6.976744186vw, 30px) auto 0 auto;
  }
}
body .section11 .container .inner_section11_thank_you {
  width: 100%;
  padding: min(1.4641288433vw, 20px) 0 0 0;
  display: flex;
  justify-content: center;
  color: var(--base_primary2);
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11_thank_you {
    font-size: min(2.7906976744vw, 12px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
    margin: min(2.3255813953vw, 10px) 0 0 0;
  }
}
body .section11 .container .inner_section11_thank_you .thank {
  font-size: min(0.878477306vw, 12px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11_thank_you .thank {
    font-size: min(2.7906976744vw, 12px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
  }
}
body .section11 .container .inner_section11_thank_you .desc {
  font-size: min(0.878477306vw, 12px);
  font-family: "Be Vietnam Medium";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section11 .container .inner_section11_thank_you .desc {
    font-size: min(2.7906976744vw, 12px);
    font-family: "Be Vietnam Medium";
    line-height: 1;
    margin: min(1.1627906977vw, 5px) auto 0 auto;
  }
}
body .section12 {
  margin: 277px 80px 0 80px;
  margin: 277px 0px 0 0px;
  position: relative;
}
@media screen and (max-width: 900px) {
  body .section12 {
    margin: 155px 0px 0 0px;
  }
}
body .section12 .seal {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 166px;
  width: 166px;
}
@media screen and (max-width: 900px) {
  body .section12 .seal {
    height: 94px;
    width: 94px;
  }
}
body .section12 .part1 {
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: space-between;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 900px) {
  body .section12 .part1 {
    flex-direction: column;
  }
}
body .section12 .part1 .txt {
  width: calc(65% - 73px);
  padding: 123px 0 127px 55px;
  font-size: min(2.635431918vw, 36px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section12 .part1 .txt {
    padding: 54px 18px 35px 18px;
    width: 100%;
    font-size: min(6.0465116279vw, 26px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
    text-align: center;
  }
}
body .section12 .part1 .txt span {
  color: var(--base_primary);
}
body .section12 .part1 .txt .title {
  margin: 0 0 10px 0;
  line-height: 1.3;
}
body .section12 .part1 .txt .desc {
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  line-height: 1.3;
}
@media screen and (max-width: 900px) {
  body .section12 .part1 .txt .desc {
    font-size: min(3.7209302326vw, 16px);
    font-family: "Be Vietnam Light";
    line-height: 1;
    line-height: 1.3;
  }
}
body .section12 .part1 .img {
  padding: 10px 0 0 0;
  width: 35%;
  margin: 0 44px 0 auto;
  display: flex;
  align-items: end;
  align-self: stretch;
}
@media screen and (max-width: 900px) {
  body .section12 .part1 .img {
    width: 100%;
  }
}
body .section12 .part1 .img svg {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 900px) {
  body .section12 .part1 .img svg {
    max-width: 174px;
    margin: 0 auto;
  }
}
body .section12 .part2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
body .section12 .part2 .sub_title {
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  padding: 80px 0 0 0;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .section12 .part2 .sub_title {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
    padding: 70px 54px 0 54px;
  }
}
body .section12 .part2 .card_group {
  display: flex;
  gap: 23px;
  padding: 32px 70px 50px 70px;
}
@media screen and (max-width: 900px) {
  body .section12 .part2 .card_group {
    padding: 20px 25px 57px 25px;
    flex-direction: column;
  }
}
body .section12 .part2 .card_group .card {
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  background: #00001b;
  padding: 36px 33px 46px 33px;
}
@media screen and (max-width: 900px) {
  body .section12 .part2 .card_group .card {
    padding: 33px 25px 33px 16px;
    flex-direction: row;
    gap: 29px;
    text-align: start;
    align-items: center;
  }
}
body .section12 .part2 .card_group .card img {
  width: auto;
  height: 141px;
}
@media screen and (max-width: 900px) {
  body .section12 .part2 .card_group .card img {
    width: 85px;
    height: auto;
  }
}
body .section12 .part2 .card_group .card .txt .title {
  margin: 20px 0 12px 0;
  font-size: min(1.756954612vw, 24px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section12 .part2 .card_group .card .txt .title {
    margin: 0 0 10px 0;
    font-size: min(4.1860465116vw, 18px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section12 .part2 .card_group .card .txt .desc {
  font-size: min(1.1713030747vw, 16px);
  font-family: "Be Vietnam Light";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  body .section12 .part2 .card_group .card .txt .desc {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Light";
    line-height: 1;
  }
}
body .section12 .part2 .bottom_bg {
  width: 100%;
  height: auto;
  aspect-ratio: 1121/180;
  position: relative;
}
body .section12 .part2 .bottom_bg svg,
body .section12 .part2 .bottom_bg img {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: -15%;
  left: 0;
  right: 0;
}
body .section12 .part3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 84px 0 0 0;
  border-radius: 0 0 10px 10px;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 {
    padding: 100px 0 0 0;
  }
}
body .section12 .part3 .getaccess_form {
  position: relative;
  aspect-ratio: 754.03/540;
  width: 61.6%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 37px 49px 48px 49px;
  position: relative;
}
body .section12 .part3 .getaccess_form .form_border {
  position: absolute;
  inset: 0;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form {
    padding: 51px 18px 0px 18px;
    padding: 51px 0px 55px 0px;
    width: calc(100% - 66px);
  }
}
body .section12 .part3 .getaccess_form .owl {
  position: absolute;
  bottom: calc(100% - 20px);
  left: calc(100% - 20px);
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .owl {
    width: 71px;
    height: auto;
    left: calc(100% - 40px);
    bottom: calc(100% + 10px);
  }
}
body .section12 .part3 .getaccess_form .title {
  text-align: center;
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  margin: 0 0 10px 0;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .title {
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section12 .part3 .getaccess_form .desc {
  text-align: center;
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  margin: 0 0 38px 0;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .desc {
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Light";
    line-height: 1;
    padding: 0 20px;
    margin: 0 0 29px 0;
  }
}
body .section12 .part3 .getaccess_form .input_group {
  z-index: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto 0 12px 0;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .input_group {
    padding: 0 27px;
    gap: 12px;
    margin: auto 0 8px 0;
  }
}
body .section12 .part3 .getaccess_form .input_group .custom_input {
  margin: 0 auto;
  max-width: 416px;
  width: 100%;
  border: 1px solid #5758ff;
  border-radius: 8px;
  display: flex;
}
body .section12 .part3 .getaccess_form .input_group .custom_input input {
  width: 100%;
  height: 100%;
  padding: 11px 24px;
  background: transparent;
  margin: 0;
  border: 0;
  outline: 0;
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  color: #fff;
}
body .section12 .part3 .getaccess_form .input_group .custom_input input:placeholder {
  color: #898989;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .input_group .custom_input input {
    padding: 9px 14px;
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Light";
    line-height: 1;
  }
}
body .section12 .part3 .getaccess_form .input_group .custom_input .custom_select {
  padding: 10px 20px;
  background: transparent;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  color: #fff;
  background: #1c1723;
  border-radius: 8px;
}
body .section12 .part3 .getaccess_form .input_group .custom_input .custom_select[attr-data="What stage is your product in?"] {
  color: #898989 !important;
}
body .section12 .part3 .getaccess_form .input_group .custom_input .custom_select option {
  color: #fff;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .input_group .custom_input .custom_select {
    padding: 9px 14px;
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Light";
    line-height: 1;
  }
}
body .section12 .part3 .getaccess_form .note {
  max-width: 308px;
  text-align: center;
  margin: 0 auto auto auto;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .note {
    padding: 0 37px;
    font-size: min(2.7906976744vw, 12px);
    font-family: "Be Vietnam Light";
    line-height: 1;
  }
}
body .section12 .part3 .getaccess_form .btn {
  background: var(--base_primary);
  font-size: min(1.317715959vw, 18px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 24px;
  border-radius: 100px;
  color: #fff;
  margin: 36px auto 0 auto;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .btn {
    margin: 30px auto 0 auto;
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section12 .part3 .getaccess_form .btn svg {
  height: 14px;
  width: 14px;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .getaccess_form .btn svg {
    height: 11px;
    width: 11px;
  }
}
body .section12 .part3 .getaccess_form .form_border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body .section12 .part3 .why_choose_us {
  margin: 150px 0 0 0;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .why_choose_us {
    margin: 90px 0 0 0;
  }
}
body .section12 .part3 .why_choose_us .title {
  margin: 0 0 32px 0;
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .why_choose_us .title {
    margin: 0 0 20px 0;
    font-size: min(5.5813953488vw, 24px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section12 .part3 .why_choose_us .card_group {
  margin: 0 74px 124px 74px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
body .section12 .part3 .why_choose_us .card_group img {
  width: calc(33% - 13.3333333333px);
  height: auto;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  body .section12 .part3 .why_choose_us .card_group img {
    width: calc(50% - 20px);
    height: auto;
  }
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .why_choose_us .card_group {
    margin: 0 25px 90px 25px;
    flex-direction: column;
  }
  body .section12 .part3 .why_choose_us .card_group > svg,
  body .section12 .part3 .why_choose_us .card_group img {
    width: 100%;
    height: auto;
  }
}
body .section12 .part3 .why_choose_us .card_group .show_desktop {
  display: block;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .why_choose_us .card_group .show_desktop {
    display: none;
  }
}
body .section12 .part3 .why_choose_us .card_group .show_mobile {
  display: none;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 .why_choose_us .card_group .show_mobile {
    display: block;
  }
}
body .section12 .part3 > .txt1 {
  font-size: min(2.3426061493vw, 32px);
  font-family: "Be Vietnam SemiBold";
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 > .txt1 {
    font-size: min(5.1162790698vw, 22px);
    font-family: "Be Vietnam SemiBold";
    line-height: 1;
  }
}
body .section12 .part3 > .txt2 {
  padding: 11px 0 204px 0;
  font-size: min(1.4641288433vw, 20px);
  font-family: "Be Vietnam Light";
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .section12 .part3 > .txt2 {
    padding: 13px 0 138px 0;
    font-size: min(3.2558139535vw, 14px);
    font-family: "Be Vietnam Light";
    line-height: 1;
  }
}
body .new_section_container {
  margin: min(18.3016105417vw, 250px) 0 0 0;
}
@media screen and (max-width: 700px) {
  body .new_section_container {
    margin: min(34.8837209302vw, 150px) 0 0 0;
  }
}
body .new_section_container .container .new_section_title {
  font-size: min(2.196193265vw, 30px);
  font-family: "Be Vietnam Bold";
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 900px) {
  body .new_section_container .container .new_section_title {
    font-size: min(5.1162790698vw, 22px);
    font-family: "Be Vietnam Bold";
    line-height: 1;
  }
}
body .new_section_container .container .new_section_box {
  position: relative;
  margin: min(1.6105417277vw, 22px) auto 0 auto;
  width: -moz-fit-content;
  width: fit-content;
}
@media screen and (max-width: 900px) {
  body .new_section_container .container .new_section_box {
    margin: min(11.8604651163vw, 51px) auto 0 auto;
  }
}
@media screen and (max-width: 700px) {
  body .new_section_container .container .new_section_box {
    width: 100%;
  }
  body .new_section_container .container .new_section_box img {
    width: 100%;
  }
}
body .new_section_container .container .new_section_box .box_floter {
  position: absolute;
  top: -20px;
  left: -50px;
  transform: rotate(-33.51deg);
}
@media screen and (max-width: 700px) {
  body .new_section_container .container .new_section_box .box_floter {
    top: -10px;
    left: -30px;
    width: 100px;
  }
}
body .new_section_container .container .new_section_box .show_desktop {
  display: block;
}
@media screen and (max-width: 700px) {
  body .new_section_container .container .new_section_box .show_desktop {
    display: none;
  }
}
body .new_section_container .container .new_section_box .show_mobile {
  display: none;
}
@media screen and (max-width: 700px) {
  body .new_section_container .container .new_section_box .show_mobile {
    display: block;
  }
}

html {
  scroll-behavior: smooth;
}

.linkedinlink {
  display: flex;
  gap: 0 8px;
  margin: 11px 0 0 0;
  width: -moz-fit-content;
  width: fit-content;
}
@media screen and (max-width: 800px) {
  .linkedinlink {
    display: none;
  }
}
.linkedinlink .txt {
  color: #fff;
  font-size: 18px !important;
  opacity: 0.5;
}
.linkedinlink > div {
  display: flex;
  align-items: center;
}

.img_border_rad {
  border-radius: min(1.4641288433vw, 20px);
}

.thankyoupage {
  padding: min(13.9092240117vw, 190px) 0 min(9.8096632504vw, 134px) 0;
}
@media screen and (max-width: 900px) {
  .thankyoupage {
    padding: 130px 0 134px 0;
  }
}
.thankyoupage .floatbtn {
  position: sticky;
  bottom: 20px;
}
.thankyoupage .text_group {
  font-size: 20px;
}
.thankyoupage .text_group > div {
  margin: 0 0 10px 0;
}
.thankyoupage .videoContainer {
  max-width: 1000px;
  margin: 0 auto;
  border: 1px solid rgba(233, 233, 233, 0.4);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.thankyoupage .videoContainer iframe {
  width: 100%;
  aspect-ratio: 543/372;
}
.thankyoupage .video_group {
  gap: 70px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 50px 0;
}
.thankyoupage .video_group .video_item {
  flex-grow: 1;
}
.thankyoupage .video_group .video_item .title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 20px 0;
}
.thankyoupage .document {
  cursor: pointer;
  background: #211f26;
  border: 1px solid rgba(233, 233, 233, 0.4);
  margin: 10px 0 0 0;
  padding: 13px 16px 13px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 0 0 10px 10px;
}
.thankyoupage .document .txt {
  margin: 0 auto 0 0;
  font-size: 18px;
}
.thankyoupage .book-a-call {
  margin: 0 auto;
}